<template>
  <div class="nameserver">
    <b-form>
      <b-row>
        <b-col sm="7" xs="12">
          <b-form-group label="Nameserver" label-for="nameserver">
            <b-form-input
              id="nameserver"
              v-model="record.value"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Nameserver',
  components: {},
  props: {
    record: {
      type: Object,
      default: () => {},
      required: false,
    },
    disable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      disabled: true,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
    ]),
  },
  watch: {
    disable(value) {
      this.disabled = value
    },
  },
  mounted() {
    this.disabled = this.disable
  },
  methods: {
    input() {
      this.$emit('input')
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  ::v-deep .row {
    margin: 0 !important;
  }
}
</style>
