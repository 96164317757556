var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "domain-record" }, [
    _c(
      "div",
      { staticClass: "record-fields" },
      [
        _c("validation-observer", {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ errors }) {
                return [
                  _c(_vm.recordTypeComponentName, {
                    tag: "component",
                    attrs: { record: _vm.localRecord, disable: !_vm.editMode },
                    on: {
                      input: function ($event) {
                        return _vm.validate(errors)
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "record-actions" },
      [
        !_vm.loading
          ? [
              !_vm.editMode
                ? [
                    _vm.enableSpinner
                      ? _c(
                          "div",
                          [
                            _vm.showNsSpinner
                              ? _c("fa-icon", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top",
                                      value: "Nameserver change is propagating",
                                      expression:
                                        "'Nameserver change is propagating'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  staticStyle: { color: "orange" },
                                  attrs: {
                                    size: "lg",
                                    icon: "spinner",
                                    spin: "",
                                  },
                                })
                              : _vm.showNsCheck
                              ? _c("fa-icon", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top",
                                      value: "Nameserver change complete",
                                      expression:
                                        "'Nameserver change complete'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  staticStyle: { color: "green" },
                                  attrs: { size: "lg", icon: "check" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("fa-icon", {
                      class: _vm.computedIconClass(_vm.enableEdit),
                      attrs: { size: "lg", icon: "edit" },
                      on: {
                        click: function ($event) {
                          return _vm.runIfEnabled(
                            _vm.enableEditMode,
                            _vm.enableEdit
                          )
                        },
                      },
                    }),
                    _c("fa-icon", {
                      class: _vm.computedIconClass(_vm.enableDelete),
                      attrs: { size: "lg", icon: "trash" },
                      on: {
                        click: function ($event) {
                          return _vm.runIfEnabled(
                            _vm.showDomainRecordDeleteModal,
                            _vm.enableDelete
                          )
                        },
                      },
                    }),
                  ]
                : _c(
                    "div",
                    { staticClass: "button-container" },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            variant: "primary",
                            disabled: !_vm.valid || !_vm.dirty,
                          },
                          on: { click: _vm.save },
                        },
                        [_vm._v("\n          Save\n        ")]
                      ),
                      _c(
                        "b-btn",
                        {
                          attrs: { variant: "outline-danger" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v("\n          Cancel\n        ")]
                      ),
                    ],
                    1
                  ),
            ]
          : _c("ct-centered-spinner"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }