var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "caa-record" },
    [
      _c(
        "b-form",
        [
          _c(
            "b-row",
            { staticClass: "custom-row" },
            [
              _c(
                "b-col",
                { attrs: { md: "2", xs: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Name", "label-for": "name" } },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          id: "name",
                          disabled: _vm.disabled,
                          placeholder: "@",
                          title: _vm.fullHostname,
                        },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "name", $$v)
                          },
                          expression: "record.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "1", xs: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Flags", "label-for": "flags" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "flags", disabled: _vm.disabled },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.flags,
                          callback: function ($$v) {
                            _vm.flags = $$v
                          },
                          expression: "flags",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "1", xs: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Tag", "label-for": "tag" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "tag", disabled: _vm.disabled },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.tag,
                          callback: function ($$v) {
                            _vm.tag = $$v
                          },
                          expression: "tag",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3", xs: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Value", "label-for": "value" } },
                    [
                      _c("b-form-input", {
                        attrs: { id: "value", disabled: _vm.disabled },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2", xs: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "TTL", "label-for": "ttl" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "ttl",
                          options: _vm.ttlOptions,
                          disabled: _vm.disabled,
                        },
                        on: { input: _vm.input },
                        model: {
                          value: _vm.record.ttl,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "ttl", $$v)
                          },
                          expression: "record.ttl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }