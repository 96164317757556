<template>
  <b-card id="add-domain-record">
    <template #header>
      Add Record
    </template>
    <domain-record
      :record="record"
      :type="type"
      @cancel="$emit('cancel')"
      @success="succeed"
    />
  </b-card>
</template>

<script>
import DomainRecord from './DnsManagement/DomainRecord/DomainRecord.vue'

export default {
  name: 'AddDomainRecord',
  components: {
    DomainRecord,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      record: {
        id: this._uid,
        type: this.type,
        temp: true,
      },
    }
  },
  methods: {
    reinit() {
      this.record = {
        id: this._uid,
        type: this.type,
        temp: true,
      }
    },
    succeed() {
      this.reinit()
      this.$emit('hide')
    },
  },
}
</script>

<style lang="scss" scoped>
#add-domain-record {
  margin: 0 -1.3rem 2rem -1.3rem;
  ::v-deep .card-body {
    padding: 0 1.2rem;
    .domain-record {
      border-top: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  #add-domain-record {
    margin: 0;
  }
}
</style>
