<template>
  <div class="domain-admin-auth-code">
    <!-- Heading ---------------------------------------------------------------------------------->
    <h4>
      <span>Domain Auth Code<span v-if="subtitle" class="subtitle"> {{ subtitle }}</span></span>
    </h4>

    <b-row>
      <b-col md="4" xs="12" class="column-spacing">
        <b-button
          variant="primary"
          :disabled="fetchingAuthCode"
          @click="showConfirmationModal"
        >
          Generate Domain Auth Code
        </b-button>
      </b-col>
      <b-col md="5" xs="12">
        <b-input-group size="small">
          <b-form-input v-model="domain.authCode" l class="auth-code-form" :type="showAuthCode ? 'text' : 'password'" readonly />
          <b-input-group-append>
            <fa-icon
              :icon="showAuthCode ? 'eye' : 'eye-slash'"
              class="mt-3 ml-2"
              type="button"
              @click="toggleShowAuthCode"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <ct-centered-spinner v-if="fetchingAuthCode">
      <p class="centered-spinner-text">
        Generating auth code...
      </p>
    </ct-centered-spinner>
    <auth-code-modal @ok="generateDomainAuthCode" @cancel="hideConfirmationModal" />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import AuthCodeModal from '@/components/Domains/AuthCodeModal.vue'

export default {
  name: 'DomainAdminAuthCode',
  components: {
    AuthCodeModal,
    CtCenteredSpinner,
  },
  mixins: [makeToastMixin],
  props: {
    subtitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetchingAuthCode: false,
      showAuthCode: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
    ]),
    authCode() {
      return this.domain.authCode
    },
  },
  watch: {
    authCode() {
      this.fetchingAuthCode = false
    },
  },
  methods: {
    ...mapActions('domains', [
      'fetchDomainAuthCode',
    ]),
    showConfirmationModal() {
      this.$bvModal.show('auth-code-modal')
    },
    hideConfirmationModal() {
      this.$bvModal.hide('auth-code-modal')
    },
    generateDomainAuthCode() {
      this.fetchingAuthCode = true
      this.fetchDomainAuthCode({ domain: this.domain })
      this.hideConfirmationModal()
    },
    toggleShowAuthCode() {
      this.showAuthCode = !this.showAuthCode
    },
  },
}
</script>

<style lang="scss" scoped>
h4 {
  padding: unset;
  margin: unset;
  height: 5.5rem;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .subtitle {
    font-weight: 200;
  }
}
.status-code {
  color: blue;
}
.centered-spinner-text {
  width: 25rem;
}

@media only screen and (max-width: 767px) {
  .column-spacing {
    margin-bottom: 1.0em;
  }
}
</style>
