<template>
  <!-- Add new email ---------------------------------------------------------------------------->
  <b-card id="add-domain-email">
    <template #header>
      Create New Email Address
    </template>
    <div class="add-domain-email-form-group">
      <b-form class="w-100">
        <b-container fluid class="p-0">
          <b-row class="d-flex flex-wrap">
            <b-col xs="12" md="4" order="1">
              <b-form-group label="Email Address" label-for="username">
                <b-input-group append="@">
                  <b-form-input
                    id="username"
                    v-model="username"
                    :disabled="loading"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col xs="12" md="4" order="2" class="w-100 domain-name">
              {{ domain.domain_name }}
            </b-col>
            <b-col xs="12" md="4" order="3">
              <validation-provider v-slot="{ validate, errors }" name="password" rules="required|domainEmailPasswordValidator">
                <b-form-group label="Password" label-for="password">
                  <b-input-group>
                    <b-form-input
                      id="password"
                      v-model="password"
                      :disabled="loading"
                      :type="showPassword ? 'text' : 'password'"
                      @input="validatePassword(validate)"
                    />
                    <b-input-group-append class="password-visibility">
                      <fa-icon
                        :icon="showPassword ? 'eye' : 'eye-slash'"
                        style="cursor: pointer;"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                    <b-card v-if="password.length && errors.length" class="small popdown">
                      {{ errors.length > 0 ? errors[0] : '' }}
                    </b-card>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <div class="item-actions">
        <template v-if="!loading">
          <b-btn
            variant="primary"
            :disabled="!isPasswordValid || username.length < 1"
            @click="save"
          >
            Save
          </b-btn>
          <b-btn
            variant="outline-danger"
            @click="hide"
          >
            Cancel
          </b-btn>
        </template>
        <ct-centered-spinner v-else />
      </div>
    </div>
  </b-card>
</template>

<script>
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { makeToastMixin } from '@/mixins/makeToastMixin.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DomainEmails',
  components: {
    CtCenteredSpinner,
  },
  mixins: [makeToastMixin],
  data() {
    return {
      loading: false,
      username: '',
      password: '',
      showPassword: false,
      errors: [],
      isPasswordValid: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
    ]),
  },
  methods: {
    ...mapActions('domainEmails', [
      'createDomainEmail',
    ]),
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    hide() {
      this.$emit('addDomainEmailClose')
    },
    async save() {
      this.loading = true
      const vm = this
      function afterFunction(result) {
        vm.$emit('addDomainEmailClose', result?.success)
        vm.loading = false
      }

      await this.createDomainEmail({
        domainId: this.domain.id,
        emailUsername: this.username,
        password: this.password,
        afterFunction: afterFunction,
      })
    },
    async validatePassword(validate) {
      this.isPasswordValid = (await validate()).valid
    },
  },
}
</script>

<style lang="scss" scoped>

#add-domain-email {
  width: 100%;

  ::v-deep {
    .add-domain-email-form-group {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-end;

      .row {
        width: 100%;
        margin-bottom: 0;
      }
      .input-group-text {
        font-weight: 900;
        font-size: 1.6rem;
        height: 46px;
        padding-top: 0;
      }
      .password-visibility {
        right: 1rem;
        position: absolute;
        top: 0.9rem;
        z-index: 1000;
      }
      .form-group, input {
        margin-bottom: 0;
      }
      .domain-name {
        font-weight: 900;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        padding-top: 1.5em;
        padding-left: 0;
      }
      #password {
        border-radius: 4px !important;
      }
      .item-actions {
        min-width: 162px;
        margin-left: 1.0em;

        button {
          font-weight: 900;
          margin: 2px 4px;
          padding-left: .8em;
          padding-right: .8em;
        }
      }
      .popdown {
        position: absolute;
        z-index: 3000;
        top: 50px;
        opacity: .9;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #add-domain-email {
    ::v-deep .add-domain-email-form-group {
      flex-direction: column;
      align-items: stretch;

      .b-form-group {
        width: 100%;
        text-align: center;
      }

      .domain-name {
        width: 100%;
        padding-top: 0.5em;
        padding-bottom: 1.5em;
        justify-content: flex-end;
      }

      .item-actions {
        width: 100%;
        margin-left: 0;
        padding-top: 1.5em;
        flex-direction: column;
      }

      button {
        width: 100%;
      }
    }
  }
}
</style>
