<template>
  <div id="domain-emails">
    <!-- Heading ---------------------------------------------------------------------------------->
    <div class="header-container">
      <h4 class="">Manage Email Accounts</h4>
      <div class="header-buttons">
        <b-button
          v-if="showAddDomainEmailButton"
          variant="primary"
          aria-label="Add New Email Account button"
          @click="showAddDomainEmail"
        >
          Add New Email Account
        </b-button>
        <b-button
          variant="outline-primary"
          aria-label="Setup Email Client button"
          @click="openSetupEmailClient()"
        >
          Setup Email Client
        </b-button>

        <b-button
          v-if="serviceIsExpiring && !serviceIsWaitingOnPayment"
          variant="primary"
          aria-label="Reactivate Business Email button"
          @click="openConfirmWindow"
        >
          Reactivate Business Email
        </b-button>
      </div>
    </div>

    <domain-service-pending-removal
      v-if="serviceIsExpiring"
      :service="service"
    />

    <div v-if="serviceIsDeleting">
      <i class="text-danger">
        Due to suspension all passwords set to your email accounts have been invalidated and removed.
        <br>
        Upon reactivation you will have to update the passwords to any email accounts
        you are wanting to authenticate via username and password with.
        <br>
        The Webmail button will still function as a method to login while you update your passwords.
      </i>
    </div>

    <!-- Add domain email ------------------------------------------------------------------------->
    <add-domain-email
      v-if="addingDomainEmail"
      @addDomainEmailClose="handleAddDomainEmailClose"
    />

    <!-- Email table ------------------------------------------------------------------------------>
    <ct-table
      v-if="domainEmails.length > 0"
      :items="domainEmails"
      :loaded="domainEmails !== null"
      :fields="tableFields"
      :is-paginated="false"
      :small="false"
      :stacked="'md'"
      :expandable="false"
    >
      <div slot="cell(actions)" slot-scope="data" class="record-actions">
        <template v-if="!loading.includes(data.item.id)">
          <span v-b-tooltip.hover.top="'Webmail for cpanel domains is unavailable for admins.'" :disabled="!disableForAdmin">
            <b-button
              variant="primary"
              :disabled="serviceIsDeleting || disableForAdmin"
              @click="launchWebmail(data.item)"
            >
              <strong>
                Webmail
              </strong>
            </b-button>
          </span>
          <b-button
            variant="outline-primary"
            :disabled="serviceIsDeleting"
            @click="changePassword(data.item)"
          >
            <strong>
              Change Password
            </strong>
          </b-button>
          <b-button
            variant="outline-danger"
            :disabled="serviceIsDeleting || disableEmailDelete"
            @click="deleteEmail(data.item)"
          >
            <strong>
              Delete
            </strong>
          </b-button>
        </template>
        <ct-centered-spinner v-else />
      </div>
    </ct-table>

    <!-- No Records of Type ----------------------------------------------------------------------->
    <ct-empty-view v-if="domainEmails.length < 1 && !addingDomainEmail" class="mt-5">
      You haven't added any email accounts to your domain.
    </ct-empty-view>

    <email-delete-modal
      @delete="confirmEmailDeleteModal"
      @hide="hideEmailDeleteModal"
    />
    <email-change-password-modal
      :email="selectedEmail"
      @hide="hideEmailChangePasswordModal"
    />
    <reactivate-domain-service-confirm-window
      v-if="showConfirmWindow"
      :service="service"
      :is-deleting="serviceIsDeleting"
      @hideConfirmWindow="hideConfirmWindow"
      @changeInProcess="changeInProcess"
    />
    <setup-email-client-modal
      @hide="hideSetupEmailClient"
    />
  </div>
</template>

<script>
import CtTable from '@/components/shared/CtTable.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { makeToastMixin } from '@/mixins/makeToastMixin.js'
import { mapActions, mapGetters } from 'vuex'

import AddDomainEmail from '@/components/Domains/panels/Emails/AddDomainEmail.vue'
import EmailDeleteModal from '@/components/Domains/panels/Emails/EmailDeleteModal.vue'
import EmailChangePasswordModal from '@/components/Domains/panels/Emails/EmailChangePasswordModal.vue'
import CtEmptyView from '@/components/shared/CtEmptyView.vue'
import DomainServicePendingRemoval
  from '@/components/Domains/DomainServicePendingRemoval.vue'
import ReactivateDomainServiceConfirmWindow
  from '@/components/Domains/ReactivateDomainServiceConfirmWindow.vue'
import SetupEmailClientModal from '@/components/Domains/panels/Emails/SetupEmailClientModal.vue'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: 'DomainEmails',
  components: {
    ReactivateDomainServiceConfirmWindow,
    DomainServicePendingRemoval,
    SetupEmailClientModal,
    CtTable,
    CtCenteredSpinner,
    AddDomainEmail,
    EmailDeleteModal,
    EmailChangePasswordModal,
    CtEmptyView,
  },
  mixins: [makeToastMixin],
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Make loading an array for row specifity
      loading: [],
      tableFields: [
        { key: 'email_address', label: 'Email Address', sortable: true, class: 'email-cell' },
        { key: 'actions', label: '', class: 'item-actions' },
      ],
      addingDomainEmail: false,
      emailAddress: null,
      password: null,
      showPassword: false,
      selectedEmail: null,
      showConfirmWindow: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'isCpanelHostingAccount',
    ]),
    ...mapGetters('domainEmails', [
      'domainEmails',
    ]),
    disableForAdmin() {
      return this.isCpanelHostingAccount && this.isAdmin
    },
    isAdmin() {
      return sessionStorage.getItem('admin-logged-in') === 'true'
    },
    disableEmailDelete() {
      return this.domainEmails.length === 1
    },
    serviceIsExpiring() {
      return this.serviceIsSuspending || this.serviceIsDeleting
    },
    serviceIsSuspending() {
      return this.service.cancellation_status === 'new'
    },
    serviceIsDeleting() {
      return this.service.cancellation_status === 'suspended'
    },
    serviceFailedToReactivate() {
      return this.service.cancellation_status === 'reactivation-failed'
    },
    serviceIsWaitingOnPayment() {
      return this.service.status === 'waiting on payment'
    },
    showAddDomainEmailButton() {
      return !this.addingDomainEmail && (!this.serviceIsExpiring && !this.serviceFailedToReactivate && !this.serviceIsWaitingOnPayment)
    },
  },
  methods: {
    ...mapActions('domainEmails', [
      'createDomainEmail',
      'deleteDomainEmail',
      'fetchMagicLink',
    ]),
    ...mapActions('products', [
      'loadProductByParams',
    ]),
    showAddDomainEmail() {
      this.addingDomainEmail = true
    },
    hideAddDomainEmail() {
      this.addingDomainEmail = false
    },
    async handleAddDomainEmailClose(success) {
      if (success === undefined) {
        this.hideAddDomainEmail()
        return
      }

      if (success) {
        this.successToast('Success', 'Created new email account.')
        this.hideAddDomainEmail()
      } else {
        this.errorToast('Error', 'Unable to create email account.')
      }
    },
    async launchWebmail(item) {
      this.$emit('webmail-visit-interaction')
      const magicLink = await this.fetchMagicLink(
        {
          domainId: this.domain.id,
          domainEmailId: item.id,
        }
      )

      // Attempt to open webmail in a new window, if it fails just redirect the current page instead
      //
      // If all else fails, after 5 seconds of no redirect happening show them an error message that tells
      // them to login at the cPanel webmail link.
      let newWindow = window.open(magicLink, '_blank')

      if(!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        window.location = magicLink

        setTimeout(() => {
          this.displayNoRedirectWebmailError(magicLink)
        }, 5000)
      }
    },

    displayNoRedirectWebmailError(magicLink) {
      if (window.location !== magicLink) {
        let toastTitle = "Webmail Redirect Error"
        let toastBody = `
          Sorry, we couldn't redirect you to your webmail do to blocked popups and redirects.
          You can enable them in your browser settings or try logging in at:
          webmail.${this.domain.domain_name}.com
        `
        this.$bvToast.toast(toastBody, {
          title: toastTitle,
          variant: 'danger',
          solid: true,
          noAutoHide: true,
        })
      }
    },

    // Setup Email Client --------------------------------------------------------------------------
    async openSetupEmailClient() {
      this.$bvModal.show('setup-email-client-modal')
    },
    hideSetupEmailClient() {
      this.$bvModal.hide('setup-email-client-modal')
    },

    // Change password -----------------------------------------------------------------------------
    changePassword(item) {
      this.selectedEmail = item
      this.logInteraction('email-change-password-modal', 'visit', 'business-email')
      this.$bvModal.show('email-change-password-modal')
    },
    hideEmailChangePasswordModal() {
      this.$bvModal.hide('email-change-password-modal')
    },

    // Delete email --------------------------------------------------------------------------------
    deleteEmail(item) {
      this.selectedEmail = item
      this.logInteraction('email-delete-modal', 'visit', 'business-email')
      this.$bvModal.show('email-delete-modal')
    },
    hideEmailDeleteModal() {
      this.$bvModal.hide('email-delete-modal')
    },
    async confirmEmailDeleteModal() {
      this.setLoading(this.selectedEmail.id)

      this.hideEmailDeleteModal()

      const afterFunction = (_result) => {
        this.unsetLoading(this.selectedEmail.id)
      }

      await this.deleteDomainEmail({
        domainId: this.domain.id,
        id: this.selectedEmail.id,
        afterFunction: afterFunction,
      })
    },

    // Loading spinner -----------------------------------------------------------------------------
    setLoading(id) {
      this.loading.push(id)
    },
    unsetLoading(id) {
      this.loading = this.loading.filter(el => el !== id)
    },
    async openConfirmWindow() {
      try {
        await this.loadProductByParams('business-email')
        this.showConfirmWindow = true
      } catch (e) {
        // Current product was not set by loadProductParams, which is required by Reactivation Confirm Window
        this.failureToast()
      }
    },
    hideConfirmWindow(){
      this.showConfirmWindow = false
    },
    changeInProcess(){
      this.inProcess = true
    },
    failureToast(){
      this.$bvToast.toast('Something went wrong, please try again later!', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    },
    logInteraction(name, action, subCategory) {
      createOrFindClientInteractionLog({
        category: 'business-identity',
        subCategory,
        objectTable: 'Domain',
        objectId: this.domain.id,
        companyId: this.domain.company_id,
        interaction: {
          name,
          action,
          type: 'button',
          adminUser: sessionStorage.getItem('admin-logged-in'),
        },
      }).catch(error => { if (process.env.NODE_ENV !== 'production') { console.error(error) } })
    },
  },
}
</script>

<style lang="scss" scoped>

#domain-emails {
  min-height: 300px;
  padding: 0 2.0em;
  border: 1px solid #D4D4D4;
  border-radius: 8px 0 0 8px;
  overflow-x: auto;

  .header-container {
    padding: 1.0em 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
      padding: 0;
    }

    .header-buttons {
      display: flex;
      flex-direction: row;
      gap: 0.5em;
    }
  }

  ::v-deep .ct-table {
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
    display: block;
    table-layout: fixed;
  }

  ::v-deep tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ::v-deep th {
    width: 100%;
    height: 3.125em;
  }

  ::v-deep td {
    word-wrap: break-word;
    white-space: normal;
    text-align: center;

    &:first-child {
      border-top: 0;
    }
  }

  ::v-deep .record-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
  }

  ::v-deep .item-actions {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
}

@media only screen and (max-width: 767px) {
  #domain-emails {
    .header-container {
      flex-direction: column;
      gap: 1.0rem;

      .header-buttons {
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }

    ::v-deep .ct-table {
      width: 100% !important;
      max-width: 100% !important;
      display: block;
      overflow-x: auto;
    }

    ::v-deep tr {
      flex-direction: column;
      width: 100%;
    }

    ::v-deep td {
      width: 100% !important;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      &:first-child {
        border-top: 1px solid black;
      }
    }

    ::v-deep .email-cell {
      width: 100% !important;
      display: flex;
      justify-content: center;
      text-align: center;
      gap: 0.5rem;
    }

    ::v-deep .record-actions {
      flex-direction: column;
    }

    ::v-deep .item-actions {
      flex-direction: column;
      width: 100%;
    }
  }
}

</style>
