<template>
  <div class="txt-record">
    <b-form>
      <b-row class="custom-row">
        <b-col md="2" sm="12">
          <b-form-group label="Host" label-for="name">
            <b-form-input
              id="name"
              v-model="record.name"
              v-b-tooltip.hover
              :disabled="disabled"
              placeholder="@"
              :title="fullHostname"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group label="Value" label-for="data">
            <b-form-input
              id="data"
              v-model="record.data"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col md="1" sm="12">
          <b-form-group label="TTL" label-for="ttl">
            <b-form-select
              id="ttl"
              v-model="record.ttl"
              :options="ttlOptions"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { TTL_OPTIONS } from '@/common/modules/domains.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Txt',
  components: {},
  props: {
    record: {
      type: Object,
      default: () => {},
      required: false,
    },
    disable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      disabled: true,
      ttlOptions: TTL_OPTIONS,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'recordHostname',
    ]),
    fullHostname() {
      return this.recordHostname(this.record)
    },
  },
  watch: {
    disable(value) {
      this.disabled = value
    },
  },
  created() {
    this.disabled = !this.record.temp ? this.disable : false
  },
  methods: {
    input() {
      this.$emit('input')
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  .txt-record {
    .custom-row {
      margin: 0 !important;
    }
  }
}
</style>
