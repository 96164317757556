var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "domain-admin-hosting" },
    [
      _c("h4", [
        _vm._v("\n    Domain Hosting"),
        _vm.subtitle
          ? _c("span", { staticClass: "subtitle" }, [
              _vm._v(" " + _vm._s(_vm.subtitle)),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "button-container" },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-3",
              attrs: {
                variant: "primary",
                disabled:
                  !_vm.wpStatus.allow_reinstall ||
                  _vm.reinstallingWordpress ||
                  _vm.domainHostingInfo?.platform === "pterodactyl",
              },
              on: {
                click: function ($event) {
                  return _vm.performWordpressReinstall()
                },
              },
            },
            [_vm._v("\n      Re-install Wordpress\n    ")]
          ),
          _c(
            "b-button",
            {
              attrs: {
                variant: "primary",
                disabled:
                  !_vm.domainHostingInfo ||
                  _vm.domainHostingInfo.platform === "cpanel",
              },
              on: {
                click: function ($event) {
                  return _vm.showWpChangePassword()
                },
              },
            },
            [_vm._v("\n      Change WP Pass\n    ")]
          ),
        ],
        1
      ),
      _vm.reinstallingWordpress
        ? _c(
            "ct-centered-spinner",
            { staticClass: "loading-spinner mb-lg-5" },
            [
              _c("p", { staticClass: "centered-spinner-text" }, [
                _vm._v("\n      Performing wordpress re-installation...\n    "),
              ]),
              _c("p", [
                _c("em", [
                  _vm._v(
                    "\n        Please do not navigate away or refresh the page.\n      "
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c("br"),
      !_vm.reinstallingWordpress
        ? _c("div", [
            _c(
              "h5",
              [
                _c("strong", { staticClass: "mr-1" }, [
                  _vm._v("Wordpress Status:"),
                ]),
                _c("br"),
                _vm.wpStatus.status !== 1
                  ? _c(
                      "span",
                      [
                        _vm._v("\n        Status Code:\n        "),
                        _c("fa-icon", {
                          style: { color: _vm.wpStatus.color },
                          attrs: { icon: _vm.wpStatus.icon },
                        }),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: "admin_status_code",
                              placement: "top",
                              offset: "60",
                              width: "25rem",
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.wpStatusMessage),
                              },
                            }),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "status-code",
                            attrs: { id: "admin_status_code" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.wpStatus.status) +
                                "\n          "
                            ),
                            _c("fa-icon", {
                              attrs: { icon: "external-link-alt" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _c(
                          "ct-centered-spinner",
                          { staticClass: "loading-spinner" },
                          [
                            _c("p", { staticStyle: { width: "25rem" } }, [
                              _vm._v(
                                "\n            Fetching Domain Hosting Status...\n          "
                              ),
                            ]),
                            _c("p", [
                              _c("em", [
                                _vm._v(
                                  "\n              This can take a minute.\n            "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                _c("br"),
                _vm.wpStatus.status !== 1
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "float-left mt-1 mb-1",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.refreshWebsiteView()
                          },
                        },
                      },
                      [
                        _vm._v("\n        Refresh\n        "),
                        _c("b-icon-arrow-clockwise"),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.wpStatus.status !== 1 && !_vm.reinstallingWordpress
        ? _c("iframe", {
            attrs: {
              id: "admin-website-view",
              src: _vm.domainUrl,
              width: "100%",
              height: "450rem",
            },
          })
        : _vm._e(),
      _c("wordpress-change-password-modal", {
        on: { hide: _vm.hideWpChangePassword },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }