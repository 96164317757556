<template>
  <div id="domain-ssl-certificate">
    <!-- Heading ---------------------------------------------------------------------------------->
    <h4>
      <span>SSL Certificate</span>
      <b-btn
        v-if="serviceIsExpiring && !serviceIsWaitingOnPayment"
        variant="primary"
        class="float-right"
        @click="openConfirmWindow"
      >
        Reactivate Domain SSL
      </b-btn>
    </h4>

    <domain-service-pending-removal
      v-if="serviceIsExpiring"
      :service="service"
    />

    <b-row v-for="(ssl, index) in domainSsl" :key="index">
      <b-col>
        <h5 class="sub-title">Status</h5>
        <span :class="`${statusClassMap[ssl.status]} ssl-certificate-status`">
          <b-icon :icon="statusIconMap[ssl.status]" />
          {{ capitalize(ssl.status) }}
        </span>
      </b-col>
      <b-col>
        <h5 class="sub-title">Actions</h5>
        <b-row>
          <b-col class="mb-4">
            <b-btn
              variant="primary"
              :disabled="isRevalidatingCertificate || (ssl.status !== 'incomplete' && ssl.status !== 'rejected')"
              @click="revalidateCertificate(ssl)"
            >
              <b-icon-arrow-clockwise /> Revalidate
            </b-btn>
          </b-col>
          <b-col class="mb-4">
            <div class="actions-section">
              <b-btn
                variant="primary"
                :disabled="isDownloadingCertificate || (ssl.status !== 'active' || !ssl.cert_order_id)"
                @click="downloadCertificate(ssl)"
              >
                <b-icon-download /> Download
              </b-btn>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <reactivate-domain-service-confirm-window
      v-if="showConfirmWindow"
      :service="service"
      :is-deleting="serviceIsDeleting"
      @hideConfirmWindow="hideConfirmWindow"
      @changeInProcess="changeInProcess"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import axiosClient from '@/http'
import DomainServicePendingRemoval
  from '@/components/Domains/DomainServicePendingRemoval.vue'
import ReactivateDomainServiceConfirmWindow
  from '@/components/Domains/ReactivateDomainServiceConfirmWindow.vue'

export default {
  name: 'DomainSslCertificate',
  components: {
    DomainServicePendingRemoval,
    ReactivateDomainServiceConfirmWindow,
  },
  mixins: [makeToastMixin],
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusIconMap: {
        active: 'check-circle-fill',
        cancelled: 'slash-circle-fill',
        expired: 'clock-fill',
        incomplete: 'triangle-half',
        unpaid: 'exclamation-triangle-fill',
        pending: 'three-dots',
        processing: 'pen-fill',
        reissue: 'arrow-clockwise',
        rejected: 'x-circle-fill',
        suspending: 'exclamation-triangle-fill',
        deleting: 'exclamation-triangle-fill',
      },
      statusClassMap: {
        active: 'ssl-certificate-success',
        cancelled: 'ssl-certificate-success',
        expired: 'ssl-certificate-danger',
        incomplete: 'ssl-certificate-warning',
        unpaid: 'ssl-certificate-danger',
        pending: 'ssl-certificate-warning',
        processing: 'ssl-certificate-warning',
        reissue: 'ssl-certificate-warning',
        rejected: 'ssl-certificate-danger',
        suspending: 'ssl-certificate-warning',
        deleting: 'ssl-certificate-danger',
      },
      isRevalidatingCertificate: false,
      isDownloadingCertificate: false,
      showConfirmWindow: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'domainSsl',
    ]),
    serviceIsExpiring() {
      return this.serviceIsSuspending || this.serviceIsDeleting
    },
    serviceIsSuspending() {
      return this.service.cancellation_status === 'new'
    },
    serviceIsDeleting() {
      return this.service.cancellation_status === 'suspended'
    },
    serviceIsWaitingOnPayment() {
      return this.service.status === 'waiting on payment'
    },
  },
  methods: {
    ...mapActions('domains', [
      'revalidateSsl',
      'fetchDomainSslDownloadUrl',
    ]),
    ...mapActions('products', [
      'loadProductByParams',
    ]),
    capitalize(string) {
      return _.capitalize(string)
    },
    async downloadCertificate(ssl) {
      this.isDownloadingCertificate = true
      const response = await axiosClient.get(await this.fetchDomainSslDownloadUrl(ssl.cert_order_id), { responseType: 'blob' })
      const href = URL.createObjectURL(response.data)

      const a = document.createElement('a')
      a.style = 'display: none'
      a.href = href
      a.target = '_blank'
      a.download = 'certificate.txt'

      document.body.appendChild(a)

      a.click()

      setTimeout(() => {
        URL.revokeObjectURL(href)
        document.body.removeChild(a)
      }, 1000)

      this.isDownloadingCertificate = false
    },
    async revalidateCertificate(ssl) {
      this.isRevalidatingCertificate = true
      const response = await this.revalidateSsl(ssl.cert_order_id)
      this.isRevalidatingCertificate = false

      if (response.success) {
        this.successToast('Success', 'SSL Certificate revalidated successfully.')
      } else {
        this.errorToast('Error', 'Unable to revalidate SSL Certificate. Please contact support.')
      }
    },
    async openConfirmWindow() {
      try {
        await this.loadProductByParams('domain-ssl')
        this.showConfirmWindow = true
      } catch (e) {
        // Current product was not set by loadProductParams, which is required by Reactivation Confirm Window
        this.failureToast()
      }
    },
    hideConfirmWindow(){
      this.showConfirmWindow = false
    },
    changeInProcess(){
      this.inProcess = true
    },
    failureToast(){
      this.$bvToast.toast('Something went wrong, please try again later!', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    },
  },
}
</script>

<style lang="scss" scoped>

#domain-ssl-certificate {
  min-height: 300px;
  padding: 0 2rem 0 2rem;
  border: 1px solid #d4d4d4;
  border-radius: 8px 0 0 8px;

  h4 {
    padding: unset;
    margin: unset;
    height: 5.5rem;
    font-weight: 900 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .subtitle {
      font-weight: 200;
    }
  }

  .sub-title {
    border-bottom: 1px solid lightgray;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .ssl-certificate-status {
    font-size: 1.5rem;
  }
  .ssl-certificate-success {
    color: green;
  }
  .ssl-certificate-warning {
    color: goldenrod;
  }
  .ssl-certificate-danger {
    color: red;
  }

  .actions-section {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .ssl-certificate-status {
    font-size: 1.125em !important;
  }
}
</style>
