var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-2 website-container" },
    [
      _vm.loaded
        ? [
            _c(
              "b-button",
              {
                staticClass: "back-button d-flex align-items-center",
                attrs: {
                  variant: "outline-secondary",
                  "aria-label": "Go back button",
                },
                on: { click: _vm.goBack },
              },
              [
                _c("feather-icon", { attrs: { type: "chevron-left" } }),
                _vm._v(" Websites\n    "),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-4", attrs: { "align-v": "center" } },
              [
                _c(
                  "b-col",
                  [
                    _c("masthead", {
                      staticClass: "websites-masthead-mobile",
                      attrs: {
                        title: _vm.domain.domain_name,
                        "show-search": false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title-icon",
                            fn: function () {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "https://" + _vm.domain.domain_name,
                                      "aria-label":
                                        _vm.domain.domain_name + "link",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("fa-icon", {
                                      staticClass: "external-link-icon",
                                      attrs: { icon: "external-link-alt" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3785229473
                      ),
                    }),
                    _c(
                      "span",
                      { staticClass: "badge badge-pill status-badge" },
                      [_vm._v(" " + _vm._s(_vm.domain.status) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("domain-management-panel", {
              key: _vm.domainServicesLength,
              attrs: { domain: _vm.domain },
            }),
          ]
        : _c("ct-centered-spinner"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }