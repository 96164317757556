<template>
  <div id="domain-website-hosting">
    <!-- Heading ---------------------------------------------------------------------------------->
    <h4>
      <span>Website Hosting<span v-if="subtitle" class="subtitle"> {{ subtitle }}</span></span>
      <b-btn
        v-if="serviceIsExpiring && !serviceIsWaitingOnPayment"
        variant="primary"
        class="float-right"
        @click="openConfirmWindow"
      >
        Reactivate Business Website
      </b-btn>
    </h4>

    <domain-service-pending-removal
      v-if="serviceIsExpiring"
      :service="service"
    />

    <br>

    <h5>
      <strong class="mr-1">Wordpress Status:</strong>

      <br>

      <span v-if="wpStatus.status !== 1">
        Status Code:
        <fa-icon :icon="wpStatus.icon" :style="{ color: wpStatus.color }" />
        <b-tooltip target="client_status_code" placement="top" offset="60" width="25rem">
          <span v-html="wpStatus.message" />
        </b-tooltip>
        <span id="client_status_code" class="status-code">
          {{ wpStatus.status }}
          <fa-icon icon="external-link-alt" />
        </span>
      </span>

      <span v-else>
        <ct-centered-spinner class="loading-spinner mb-lg-5">
          <p class="centered-spinner-text">
            Fetching Domain Hosting Status...
          </p>
          <p>
            <em>
              This can take a minute.
            </em>
          </p>
        </ct-centered-spinner>
      </span>

      <br>

      <b-btn
        v-if="wpStatus.status !== 1"
        variant="primary"
        class="float-left mt-1 mb-1"
        @click="refreshWebsiteView()"
      >
        Refresh
        <b-icon-arrow-clockwise />
      </b-btn>
    </h5>
    <iframe
      v-if="wpStatus.status !== 1"
      id="client-website-view"
      :src="domainUrl"
      width="100%"
      height="450rem"
    />
    <reactivate-domain-service-confirm-window
      v-if="showConfirmWindow"
      :service="service"
      :is-deleting="serviceIsDeleting"
      @hideConfirmWindow="hideConfirmWindow"
      @changeInProcess="changeInProcess"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import DomainServicePendingRemoval
  from '@/components/Domains/DomainServicePendingRemoval.vue'
import ReactivateDomainServiceConfirmWindow
  from '@/components/Domains/ReactivateDomainServiceConfirmWindow.vue'

export default {
  name: 'DomainWebsiteHosting',
  components: {
    ReactivateDomainServiceConfirmWindow,
    DomainServicePendingRemoval,
    CtCenteredSpinner,

  },
  mixins: [makeToastMixin],
  props: {
    subtitle: {
      type: String,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      wpStatus: null,
      domainUrl: "",
      showConfirmWindow: false,
      inProgress: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
    ]),
    serviceIsExpiring() {
      return this.serviceIsSuspending || this.serviceIsDeleting
    },
    serviceIsSuspending() {
      return this.service.cancellation_status === 'new'
    },
    serviceIsDeleting() {
      return this.service?.cancellation_status === 'suspended'
    },
    serviceIsWaitingOnPayment() {
      return this.service.status === 'waiting on payment'
    },
  },
  watch: {
    'domain': {
      async handler(value) {
        if (value) {
          await this.fetchWpStatus()
          this.domainUrl = `https://www.${value.domain_name}`
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('domains', [
      'fetchHostingStatus',
    ]),
    ...mapActions('products', [
      'loadProductByParams',
    ]),
    async fetchWpStatus() {
      if (this.domain) {
        this.setLoadingStatus()
        const result = await this.fetchHostingStatus(this.domain.id.toString())
        const hostingStatus = result.hosting_status
        const allowReinstall = result.allow_reinstall

        if (hostingStatus >= 200 && hostingStatus < 300) {
          this.wpStatus = {
            status: hostingStatus,
          }

          if (allowReinstall) {
            this.wpStatus.icon = "check-circle"
            this.wpStatus.color = "orange"
            this.wpStatus.message = "<p>Domain hosting is functioning normally but there is an issue with the setup, contact support if issues occur.</p>"
          } else {
            this.wpStatus.icon = "check-circle"
            this.wpStatus.color = "green"
            this.wpStatus.message = "<p>Domain hosting is functioning normally.</p>"
          }

        } else if (hostingStatus >= 300 && hostingStatus < 400) {
          this.wpStatus = {
            status: hostingStatus,
            icon: "times-circle",
            color: "orange",
            message: this.serviceIsDeleting ? "<p>Your website has been suspended.</p>" : "<p>Hosting Redirection: This account has been suspended or there's a redirection on the WP site, contact support if issues occur.</p>",
          }
        } else if (hostingStatus >= 400 && hostingStatus < 500) {
          this.wpStatus = {
            status: hostingStatus,
            icon: "times-circle",
            color: "red",
            message: this.serviceIsDeleting ? "<p>Your website has been suspended.</p>" : "<p>Request Error: Hosting is not functioning properly, please contact support.</p>",
          }
        } else {
          this.wpStatus = {
            status: hostingStatus,
            icon: "times-circle",
            color: "red",
            message: this.serviceIsDeleting
              ? "<p>Your website has been suspended.</p>"
              : "<p>Error: Hosting is not functioning properly. If you just set the service up, it can take a few minutes for the website to completely boot up. <br> This view can also be caused by not having an ssl certificate setup.</p><p>If the error still persists, please contact support.</p>",
          }
        }
      } else {
        this.wpStatus = {
          status: 100,
          icon: "check-circle",
          color: "orange",
          message: "<p>Loading status please wait...</p>",
        }
      }
    },
    setLoadingStatus(){
      this.wpStatus = {
        status: 1,
        icon: "spinner",
        color: "orange",
        message: "<p>Loading domain hosting status, please wait...</p>",
      }
    },
    async openConfirmWindow(){
      try {
        await this.loadProductByParams('business-website-hosting')
        this.showConfirmWindow = true
      } catch (e) {
        // Current product was not set by loadProductParams, which is required by Reactivation Confirm Window
        this.failureToast()
      }
    },
    hideConfirmWindow(){
      this.showConfirmWindow = false
    },
    changeInProcess(){
      this.inProcess = true
    },
    failureToast(){
      this.$bvToast.toast('Something went wrong, please try again later!', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    },
    async refreshWebsiteView() {
      await this.fetchWpStatus()
      window.frames['client-website-view'].src = this.domainUrl
    },
  },
}
</script>

<style lang="scss" scoped>

#domain-website-hosting {
  min-height: 300px;
  padding: 0 2rem 0 2rem;
  border: 1px solid #d4d4d4;
  border-radius: 8px 0 0 8px;

  h4 {
    padding: unset;
    margin: unset;
    height: 5.5rem;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .subtitle {
      font-weight: 200;
    }
  }
  .status-code {
    color: blue;
  }
  .centered-spinner-text {
    width: 25rem;
  }
}
</style>
