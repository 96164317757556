<template>
  <div class="caa-record">
    <b-form>
      <b-row class="custom-row">
        <b-col md="2" xs="12">
          <b-form-group label="Name" label-for="name">
            <b-form-input
              id="name"
              v-model="record.name"
              v-b-tooltip.hover
              :disabled="disabled"
              placeholder="@"
              :title="fullHostname"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col md="1" xs="12">
          <b-form-group label="Flags" label-for="flags">
            <b-form-input
              id="flags"
              v-model="flags"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col md="1" xs="12">
          <b-form-group label="Tag" label-for="tag">
            <b-form-input
              id="tag"
              v-model="tag"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" xs="12">
          <b-form-group label="Value" label-for="value">
            <b-form-input
              id="value"
              v-model="value"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" xs="12">
          <b-form-group label="TTL" label-for="ttl">
            <b-form-select
              id="ttl"
              v-model="record.ttl"
              :options="ttlOptions"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { TTL_OPTIONS } from '@/common/modules/domains.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Caa',
  components: {},
  props: {
    record: {
      type: Object,
      default: () => {},
      required: false,
    },
    disable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      disabled: true,
      ttlOptions: TTL_OPTIONS,
      flags: "",
      tag: "",
      value: "",
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'recordHostname',
    ]),
    fullHostname() {
      return this.recordHostname(this.record)
    },
  },
  watch: {
    disable(value) {
      this.disabled = value
    },
    record() {
      this.setData()
    },
  },
  mounted() {
    this.setData()
  },
  created() {
    this.disabled = !this.record.temp ? this.disable : false
  },
  methods: {
    input() {
      this.record.data = `${this.flags || ""} ${this.tag || ""} ${this.value || ""}`
      this.$emit('input')
    },
    setData() {
      if (this.record.data) {
        let data = this.record.data?.split(" ")
        this.flags = data[0]
        this.tag = data[1]
        this.value = data[2]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  .caa-record {
    .custom-row {
      margin: 0 !important;
    }
  }
}
</style>
